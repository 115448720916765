import React, { Component } from 'react'
import Cmp from './NewsPage.js'
import { StaticQuery, graphql } from 'gatsby'

class Connected extends Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
          query {
            enNews: allWordpressPost(
              filter: { kk_language: { slug: { eq: "en" } } }
              sort: {fields: [date],
              order: DESC}
            ) {
              edges {
                node {
                  id
                  slug
                  date
                  status
                  title
                  content
                  path
                  featured_media {
                    localFile {
                      childImageSharp {
                        fixed(width: 360, height: 300, quality: 92) {
                          aspectRatio
                          width
                          height
                          src
                        }
                      }
                    }
                  }
                  __typename
                  categories {
                    id
                  }
                  format
                  excerpt
                  link
                  type
                }
              }
            }
            isNews: allWordpressPost(
              sort: {fields: [date],order: DESC}
              filter: { kk_language: { slug: { eq: "is" } } }
            ) {
              edges {
                node {
                  id
                  slug
                  date
                  status
                  title
                  content
                  path
                  featured_media {
                    localFile {
                      childImageSharp {
                        fixed(width: 360, height: 300, quality: 92) {
                          aspectRatio
                          width
                          height
                          src
                        }
                      }
                    }
                  }
                  __typename
                  categories {
                    id
                  }
                  format
                  excerpt
                  link
                  type
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <Cmp {...data} />
          )
        }}
      />
    )
  }
}

export default Connected
